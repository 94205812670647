body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Degular';
  src: url('./assets/fonts/Degular\ Medium.otf') format('opentype');
}

@font-face {
  font-family: 'P22MackinacPro';
  src: url('./assets/fonts/P22\ Mackinac\ Pro\ Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Restora';
  src: url('https://storage.googleapis.com/brand-storage/typography/Restora/RestoraExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Restora';
  src: url('https://storage.googleapis.com/brand-storage/typography/Restora/RestoraThinItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }
a:active { text-decoration: none; }